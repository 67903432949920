<template>
    <!-- Ai创作 -->
    <div class="AiCreate">
        <div class="headerTitle">AI创作</div>
        <div class="tabBox">
            <div class="left" @click="BroadcastCopy">
                <div class="title">口播文案</div>
                <div class="content">
                    <div class="img"><img src="./assets/koubo.png" alt=""></div>
                    <div class="textBox">
                        <div>轻松打造爆款口播，让你的表达震撼全场</div>
                    </div>
                </div>
                <div class="introduce">口播视频必备神器</div>
            </div>
            <div class="right">
                <div class="item" @click="tabRight(item)" :style="{ background: item.back }" v-for="(item, index) in tabRightList" :key="index">
                    <div class="img"><img :src="item.img" alt=""></div>
                    <div class="textBox">
                        <div class="title">{{ item.title }}</div>
                        <div class="introduce">{{ item.introduce }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 查看全部作品 -->
        <div class="allWorks" >
            <div @click="myWorks">查看全部作品</div>
        </div>
        <!-- 广告 -->
        <div class="advertisement">
            <div class="title">广告</div>

            <div class="img">
                <img src="./assets/guanggao.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tabRightList: [
                {
                    title: '文案改写',
                    introduce: '润色/缩写/扩写',
                    back: 'linear-gradient(270deg, #B0C2FF 0%, #C79BFF 100%',
                    img: require('./assets/t-r-1.png'),
                    path:'/CopyRewriting'
                },
                {
                    title: '标题生成',
                    introduce: '轻松生成爆款标题',
                    back: 'linear-gradient(90deg, #78B5FF 0%, #E1CCFA 100%, #00D5FF 100%)',
                    img: require('./assets/t-r-2.png'),
                    path:'/TitleGeneration'
                },
                {
                    title: '语言翻译',
                    introduce: '精准合理翻译',
                    back: 'linear-gradient(90deg, #5493FF 0%, rgba(128, 219, 255, 0.65) 100%)',
                    img: require('./assets/t-r-3.png'),
                    path:'/LanguageTranslation'
                },
                {
                    title: '产品营销',
                    introduce: '快速搞定营销文案',
                    back: ' linear-gradient(90deg, #00D9FA 0%, rgba(177, 160, 254, 0.69) 100%)',
                    img: require('./assets/t-r-4.png'),
                    path:'/ProductMarketing'
                },
                {
                    title: '笔记创作',
                    introduce: '即刻记录自己的想法',
                    back: 'linear-gradient(270deg, #C3BEFE 0%, #847EFF 100%)',
                    img: require('./assets/t-r-5.png'),
                    path:'/NoteCreation'
                },
                {
                    title: '更多内容',
                    introduce: '欢迎随时联系我们',
                    back: 'linear-gradient(90deg, #A688FF 0%, #BDBAF6 100%)',
                    img: require('./assets/t-r-6.png')
                },
            ]
        }
    },
    methods:{
        BroadcastCopy(){
            this.$message({message: '功能开发中，敬请期待！', type: 'success'});
            // this.$router.push('/BroadcastCopy')
        },
        myWorks(){
            this.$message({message: '功能开发中，敬请期待！', type: 'success'});
            // this.$router.push('/myWorks')
        },
        tabRight(item){
            this.$message({message: '功能开发中，敬请期待！', type: 'success'});
            // if(!localStorage.getItem('token')) return this.$message.error('请先登录');
            // if(item.path == undefined) return this.$message({message: '功能开发中，敬请期待！', type: 'success'});
            // this.$router.push(item.path)
        }
    }
}
</script>
<style lang="less" scoped>
.AiCreate {
    background-color: #fff;
    height: 840px;
    border-radius: 10px;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.headerTitle {
    height: 81px;
    line-height: 81px;
    text-align: center;
    border-bottom: 1px solid #E7E7E7;
    font-size: 24px;
    color: #3D3D3D;
    position: sticky;
    top: 0;
    background-color: #fff;
}

.tabBox {
    padding: 20px;
    display: flex;

    .left {
        padding: 20px;
        width: 330px;
        height: 258px;
        border-radius: 20px;
        background: linear-gradient(90deg, #4D88FF 0%, #7BB2FF 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        margin-left: 10px;
        .title {
            font-size: 28px;
            color: #fff;
        }

        .content {
            width: 308px;
            height: 72px;
            border-radius: 10px;
            padding: 27px 10px;
            background: #88B0FF;
            display: flex;

            .textBox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 18px;
                color: #fff;
                line-height: 45px;
            }
        }

        .introduce {
            font-size: 24px;
            color: #fff;
        }
    }

    .right {
        display: flex;
        flex-wrap: wrap;

        .item {
            margin-top: 5px;
            width: 329px;
            height: 100px;
            border-radius: 20px;
            margin-left: 20px;
            display: flex;
            padding: 19px 15px;
            cursor: pointer;

            .img {
                width: 100px;
                height: 100px;
                border-radius: 10px;
                background-color: #E8E7FF;
                display: flex;
                justify-content: center;

                img {
                    width: 85px;
                    height: 85px;
                    margin-top: 7.5px;
                }
            }

            .textBox {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 10px;

                .title {
                    font-size: 28px;
                    color: #FFFFFF;
                }

                .introduce {
                    font-size: 24px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.allWorks {
    padding: 10px 27px;
    display: flex;
    justify-content: right;

    div {
        cursor: pointer;
        padding: 5px 10px;
        font-size: 20px;
        color: #FFFFFF;
        border-radius: 5px;
        background: #549AFE;
    }
}

.advertisement {
    padding:20px;
    .title {
        font-size: 24px;
        line-height: 90px;
        color: #3D3D3D;
    }
    img{
        width: 1535px;
    }
}
</style>